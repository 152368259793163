<template>
  <div>
    <nav class="sidebar sidebar-dark bg-dark">
      <div class="sidebar-header">
        <a href="/dashboard" class="sidebar-brand"><span>BERT</span></a>

        <div class="sidebar-toggler not-active">
          <span></span><span></span><span></span>
        </div>
      </div>

      <div class="sidebar-body">
        <ul class="nav">
          <li class="nav-item nav-category">{{ $t("MainTxt") }} </li>

          <li class="nav-item active">
            <a href="/dashboard" class="nav-link" @click.prevent="linkDashboard()">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">{{ $t("dashboardMenu") }}</span>
            </a>
          </li>

          <li class="nav-item nav-category">{{ $t("transactionData") }}</li>

          <span v-for="permision in permision_role" :key="permision.permission_role_id">
            <span v-if="permision.permission.controller == 'EventFormController'">
              <li class="nav-item">
                <a href="/event-form" class="nav-link">
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">Event Form</span>
                </a>
              </li>
            </span>

            <span v-if="permision.permission.controller == 'RedeemProdukController'">
              <li class="nav-item">
                <a href="/redeem-produk" class="nav-link">
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">Redeem Produk</span>
                </a>
              </li>
            </span>

            <span v-if="permision.permission.controller == 'CampaignController'">
              <li class="nav-item">
                <a href="/campaign" class="nav-link">
                  <i class="link-icon" data-feather="box"></i>
                  <span class="link-title">Campaign</span>
                </a>
              </li>
            </span>
          </span>
          <br />

          <span>
            <li class="nav-item nav-category">{{ $t("AdministrationTxt") }}</li>

            <span v-for="permision in permision_role" :key="permision.permission_role_id">
              <span v-if="permision.permission.controller == 'BannerController'">
                <li class="nav-item">
                  <a href="/banner" class="nav-link">
                    <i class="link-icon" data-feather="box"></i>
                    <span class="link-title">Master Banner</span>
                  </a>
                </li>
              </span>

              <span v-if="permision.permission.controller == 'ArticleController'">
                <li class="nav-item">
                  <a href="/article" class="nav-link">
                    <i class="link-icon" data-feather="box"></i>
                    <span class="link-title">Master Article</span>
                  </a>
                </li>
              </span>
              
              <span v-if="permision.permission.controller == 'ProdukController'">
                <li class="nav-item">
                  <a href="/produk" class="nav-link">
                    <i class="link-icon" data-feather="box"></i>
                    <span class="link-title">Master Produk</span>
                  </a>
                </li>
              </span>

              <span v-if="permision.permission.controller == 'EventTypeController'">
                <li class="nav-item">
                  <a href="/event-type" class="nav-link">
                    <i class="link-icon" data-feather="box"></i>
                    <span class="link-title">Master Event Type</span>
                  </a>
                </li>
              </span>

              <span v-if="permision.permission.controller == 'EventController'">
                <li class="nav-item">
                  <a href="/event" class="nav-link">
                    <i class="link-icon" data-feather="box"></i>
                    <span class="link-title">Master Event</span>
                  </a>
                </li>
              </span>

              <span v-if="permision.permission.controller == 'CommunityController'">
                <li class="nav-item">
                  <a href="/community" class="nav-link">
                    <i class="link-icon" data-feather="box"></i>
                    <span class="link-title">Master Community</span>
                  </a>
                </li>
              </span>

              <span v-if="permision.permission.controller == 'WaGroupController'">
                <li class="nav-item">
                  <a href="/wa-group" class="nav-link">
                    <i class="link-icon" data-feather="box"></i>
                    <span class="link-title">Master WA Group</span>
                  </a>
                </li>
              </span>              

              <span v-if="permision.permission.controller == 'SettingController'">
                <li class="nav-item">
                  <a :href='setting_id' class="nav-link">
                    <i class="link-icon" data-feather="box"></i>
                    <span class="link-title">Setting</span>
                  </a>
                </li>
              </span>

              <span v-if="permision.permission.controller == 'MemberController'">
                <li class="nav-item">
                  <a href="/member" class="nav-link" @click.prevent="linkMember()">
                    <i class="link-icon" data-feather="box"></i>
                    <span class="link-title">Setting</span>
                  </a>
                </li>
              </span>


              <span v-if="permision.permission.controller == 'UserController'">
                <li class="nav-item">
                  <a href="/users" class="nav-link">
                    <i class="link-icon" data-feather="user"></i>
                    <span class="link-title">{{ $t("txtUsers") }}</span>
                  </a>
                </li>
              </span>

              <span v-if="permision.permission.controller == 'RoleController'">
                <li class="nav-item">
                  <a href="/role" class="nav-link">
                    <i class="link-icon" data-feather="settings"></i>
                    <span class="link-title">{{ $t("txtRoles") }}</span>
                  </a>
                </li>
              </span>

              <span v-if="permision.permission.controller == 'PermissionController'">
                <li class="nav-item">
                  <a href="/permission" class="nav-link">
                    <i class="link-icon" data-feather="message-square"></i>
                    <span class="link-title">{{ $t("txtPermission") }}</span>
                  </a>
                </li>
              </span>

              <span v-if="permision.permission.controller == 'PermissionRoleController'
                ">
                <li class="nav-item">
                  <a href="/rolpermis" class="nav-link">
                    <i class="link-icon" data-feather="lock"></i>
                    <span class="link-title">{{
                      $t("txtPermissionRole")
                    }}</span>
                  </a>
                </li>
              </span>
            </span>
          </span>
        </ul>
      </div>
    </nav>
  </div>
</template>


<script>
export default {
  name: "sidebarMenu",
  props: {
    classMenu: {
      type: String,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      locale: "",
      name: "",
      email: "",
      role: "",
      permision_role: [],
      user_type: '',
      setting_id : '/setting/detail/'+ this.$onRandom(1)
    };
  },
  watch: {},
  methods: {
    linkMember() {
      this.$router.push("/member");
    },
    linkCommunity() {
      this.$router.push("/community");
    },
    linkEvent() {
      this.$router.push("/event");
    },
    linkPeminjaman() {
      this.$router.push("/peminjaman");
    },
    linkDashboard() {
      this.$router.push("/dashboard");
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.name = userDatas.sub.name;
      this.email = userDatas.sub.email;
      this.role = userDatas.sub.role.name;
      this.user_type = userDatas.sub.user_type;
      this.permision_role = userDatas.sub.permision_role;

    },
        
  },
  events: {},
  created: function () { },
  mounted() {
    this.fetchIt();

  },
};
</script>
<style scoped></style>