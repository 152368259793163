<template>
  <div>
    <div v-if="isLoading">
      <!--begin::Loading Content-->
      <div class="main-wrapper">
        <div class="page-wrapper full-page">
          <div class="container">
            <div class="row">
              <!--begin::Card-->
              <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                <div class="overlay-layer card-rounded bg-opacity-5">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
              <!--end::Card-->
            </div>
          </div>
        </div>
      </div>
      <!--end::Loading Content-->
    </div>
    <div v-else>
      <div class="App">
        <div class="navbars-body section-home" v-scroll="handleScroll">
          <banner-component />
          <img v-if="!detailUser.image" class="profile-picture" src="/assets/images/maternity.png" alt="Profile" />
          <img v-else class="profile-picture" :src="detailUser.image" alt="Profile" />

          <p>
            Halo, {{ detailUser.name }}<br />
            <!-- <b>{{ detailUser.name }}</b> -->
          </p>

          <div class="section-point">
            <p>
              Total Poin<br />
              <b>{{ points.point }}</b>
            </p>

            <a href='/user/reward/add'>
              <button class="add">Tambah Poin</button>
            </a>
            <a href='/user/reward'>
              <button class="detail">Detail Poin</button>
            </a>
            <a v-if="detailUser.community.community !== 'Bert Influencer'" href='/user/redeem'>
              <button class="redeem">Redeem Poin</button>
            </a>
          </div>

          <div class="section-point">
            <p>Katalog Bert</p>
            <slick ref="slick" :options="slickOptions">
              <div>
                <img src="/assets/images/katalog_bert_new.png" alt="Product Image" class="product-image">
              </div>

              <!-- Custom Previous Button -->
              <div slot="prevArrow" class="custom-prev-arrow">
                <!-- You can use icons or custom content for the button -->
                <i class="fas fa-chevron-left"></i>
              </div>

              <!-- Custom Next Button -->
              <div slot="nextArrow" class="custom-next-arrow">
                <!-- You can use icons or custom content for the button -->
                <i class="fas fa-chevron-right"></i>
              </div>
            </slick>

          </div>

        </div>
        <navbar-component />

      </div>


    </div>
  </div>
</template>


<script>
import navbarComponent from "@/components/_partials/navbars";
import bannerComponent from "@/components/_partials/banner";
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

export default {
  name: "HomeUser",
  components: {
    "navbar-component": navbarComponent,
    "banner-component": bannerComponent,
    Slick
  },
  data() {
    return {
      points: [],
      code: "",
      locale: "",
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      isLoading: false,
      detailUser: [],
      errors: [],
      slickOptions: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        // prevArrow: '<button class="slick-prev" aria-label="Previous" title="Previous">Previous</button>',
        // nextArrow: '<button class="slick-next" aria-label="Next" title="Next">Next</button>',
      },
    };
  },
  watch: {},
  methods: {
    
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // let rolePermision = [];
      // userDatas.sub.permision_role.forEach(function(permision){
      //   if(permision.permission.controller == 'EventController'){
      //     rolePermision = permision;
      //   }
      // });

      // if(!rolePermision.method_read){
      //   this.$router.push("/authorized-error");
      // }
      // this.permision_role = rolePermision;


    },

    getPoint() {
      const userDatas = this.$getUserInfo();
      var user_id = userDatas.sub.user_id;
      const baseURI = this.$settings.endPoint + "pengguna/detail/" + user_id;
      this.isLoading = true;
      return this.$http
        .get(
          baseURI, { timeout: 10000 }
        )
        .then((response) => {
          this.isLoading = false;
          this.points = response.data.datas;
        }).catch(error => {

          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));

          } else {

            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 500) {
                this.$router.push('/server-error');
              } else if (error.response.status === 401) {
                this.$router.push("/authorized-error");
              } else {
                this.$router.push('/page-not-found');
              }
            }

          }
        });


    },
    handleChange(code) {
      this.code = code;
    },
    refreshCaptchaCode() {
      this.$refs.captcha.refreshCaptcha();
    },
    makeOffer() {

    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
  },
  events: {},
  created: function () { },
  mounted() {
    this.loading();
    this.fetchIt();
    this.getPoint();
  },
};
</script>
<style scoped>
@import '../_partials/partial.css';
body, html {
  overscroll-behavior: none;
}
.product-slide {
  position: relative;
}

.product-image {
  width: 100%;
  height: 350px;
}

.product-details {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
}

.product-details h3 {
  margin: 0;
}

.exchange-button {
  background-color: #4f3387;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.exchange-button:hover {
  background-color: #45a049;
}

.custom-prev-arrow,
.custom-next-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #fff;
  background-color: #333;
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

.custom-prev-arrow {
  left: 10px;
}

.custom-next-arrow {
  right: 10px;
}
</style>