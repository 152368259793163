<template>
    <div>
        <div v-if="isLoading">
            <!--begin::Loading Content-->
            <div class="main-wrapper">
                <div class="page-wrapper full-page">
                    <div class="container">
                        <div class="row">
                            <!--begin::Card-->
                            <div class="text-center mb-3 mt-4 overlay overlay-block" style="background-color: none">
                                <div class="overlay-layer card-rounded bg-opacity-5">
                                    <div class="spinner-border text-primary" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <!--end::Card-->
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Loading Content-->
        </div>
        <div v-else>
            <div class="App">
                <div class="navbars-body section-home">
                    <div class="header">
                        <img @click="previousPage" src="/assets/images/icon_back.png" alt="Back Icon" />
                        Poin Anda : {{ points.point }}
                    </div>
                    <button class="redeem" @click.prevent="riwayat"> Riwayat Redeem >> </button>

                    <div v-if="show == false" class="product-container">
                        <div class="product" v-for="product in products" :key="product.id">
                            <img :src="product.image" :alt="product.nama">
                            <div style="min-height: 55px;">
                                <h5 class="product-name">{{ product.nama }}</h5>
                            </div>

                            <p class="product-stock">In Stock: {{ product.stok }} {{ product.satuan }}</p>
                            <p class="product-description">Point: {{ product.point }}</p>
                            
                            <button v-if="setting.hari_redeem == todayName || setting.hari_redeem2 == todayName"
                                @click="exchangePoints(product.id, product.nama, product.point)" class="btn btn-primary"
                                style="width:100%;font-size:8pt">Exchange Points</button>
                        </div>
                    </div>

                    <!--div class="section-point">
                        
                        <slick ref="slick" :options="slickOptions">
                            <div v-for="product in products" :key="product.id">
                                <img :src="product.image" alt="Product Image" class="product-image">
                                <div class="detail-info">{{ product.nama }}</div>
                                <div class="detail-poin">{{ product.point }} Points</div>
                                <div class="detail-available">{{ product.stok > 0 ? "Available" : "Empty" }}</div>
                                <button @click="exchangePoints(product.id,product.nama,product.point)" class="exchange-button">Exchange Points</button>
                            </div>

                            
                            <div slot="prevArrow" class="custom-prev-arrow">
                                <i class="fas fa-chevron-left"></i>
                            </div>
                            <div slot="nextArrow" class="custom-next-arrow">
                                <i class="fas fa-chevron-right"></i>
                            </div>
                        </slick>                       

                    </div>-->

                    <div v-if="show" class="section-point" style="margin-top:15px !important;">
                        <form @submit.prevent="submitData" class="section-edit-profile">
                            <a href="#" @click="changeShow">Cancel</a>

                            <div class="overlay-container">
                                <label>Produk</label>
                                <input type="text" name='produk' v-model="forms.produk" />
                            </div>
                            <div class="overlay-container">
                                <label>Nama</label>
                                <input required type="text" name='name' v-model="forms.nama_lengkap" />
                            </div>

                            <div class="overlay-container">
                                <label>No. Handphone</label>
                                <input required type="tel" name='phone' v-model="forms.wa" />
                            </div>

                            <div class="overlay-container">
                                <label>Alamat Lengkap</label>
                                <textarea required type="text" name='address' v-model="forms.alamat_lengkap" />
                            </div>

                            <div class="overlay-container">
                                <label>Provinsi</label>
                                <!-- provinsi -->
                                <select name='provinsi' v-model="modelProvinsi" @change="loadKota(modelProvinsi.kode)">
                                    <option value="">Select an option</option>
                                    <option v-for="item in provinsi" :value="item" :key="item">{{ item.nama
                                        }}</option>
                                </select>
                            </div>

                            <div class="overlay-container">
                                <label>Kota</label>
                                <!-- kota -->
                                <select required name='kota' v-model="modelKota" @change="loadKecamatan(modelKota.kode)">
                                    <option value="">Select an option</option>
                                    <option v-for="item in kota" :value="item" :key="item">{{ item.nama }}</option>
                                </select>
                            </div>

                            <div class="overlay-container">
                                <label>Kecamatan</label>
                                <!-- kota -->
                                <select required name='kecamatan' v-model="modelKecamatan">
                                    <option value="">Select an option</option>
                                    <option v-for="item in kecamatan" :value="item.nama" :key="item">{{
                                        item.nama }}</option>
                                </select>
                            </div>

                            <div class="overlay-container">
                                <label>Kode POS</label>
                                <input required type='text' name='kode_pos' v-model="forms.kode_pos" />

                            </div>

                            <button class="btn btn-primary btn-sm" type="submit">
                                <i class="link-icon" data-feather="save"></i>
                                Submit
                            </button>

                        </form>

                        <div class="attention-box">
                            <h2>Terms and Conditions:</h2>
                            <ol>
                                <li>Product akan dikirim setiap 2 minggu sekali menyesuaikan tim pengiriman barang & dihubungi oleh Admin.</li>
                                <li>Bersedia membuat konten sekreatif mungkin sesuai yang diinfokan Admin.</li>
                                <li>Deadline konten H+10 setelah barang sampai & Jika konten sudah melewati deadline maka redeem selanjutnya tidak tervalidasi.</li>
                            </ol>
                        </div>


                    </div>


                </div>
                <navbar-component page="home" />

            </div>


        </div>
    </div>
</template>


<script>
import navbarComponent from "@/components/_partials/navbars";
// import Slick from 'vue-slick';
// import 'slick-carousel/slick/slick.css';

export default {
    name: "RedeemUser",
    components: {
        "navbar-component": navbarComponent,
        // Slick
    },
    data() {
        return {
            provinsi: [],
            kota: [],
            kecamatan: [],
            modelProvinsi: [],
            modelKota: [],
            modelKecamatan: "",
            points: [],
            todayName: '',
            setting: [],
            code: "",
            locale: "",
            maxToasts: 100,
            position: "up right",
            closeBtn: true,
            isLoading: false,
            detailUser: [],
            errors: [],
            products: [],
            show: false,
            // slickOptions: {
            //     arrows: false,
            //     slidesToShow: 1,
            //     slidesToScroll: 1,
            //     autoplay: true,
            //     autoplaySpeed: 3000,
            //     // prevArrow: '<button class="slick-prev" aria-label="Previous" title="Previous">Previous</button>',
            //     // nextArrow: '<button class="slick-next" aria-label="Next" title="Next">Next</button>',
            // },
            forms: [],
        };
    },
    watch: {},
    methods: {
        /*loadProvinsi() {
            const baseURI = this.$settings.endPoint + "master-kota/province";
            this.isLoading = true;
            return this.$http
                .get(
                    baseURI,
                    {
                        timeout: 100000,
                        'headers': {
                            'AppToken': this.$settings.jwtSecret,
                        }
                    }
                )
                .then((response) => {
                    this.isLoading = false;
                    this.provinsi = response.data.datas;
                }).catch(error => {

                    console.log('Error:', error);

                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });
        },*/
        loadProvinsi() {
            const baseURI = this.$settings.endPoint + "daerah/select-provinsi";
            this.isLoading = true;
            return this.$http
                .get(
                    baseURI,
                    {
                        timeout: 100000,
                    }
                )
                .then((response) => {
                    this.isLoading = false;
                    console.log(response.data.datas)
                    this.provinsi = response.data.datas;
                }).catch(error => {

                    console.log('Error:', error);

                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });
        },
        /*loadKota() {
            const baseURI = this.$settings.endPoint + "master-kota/city/" + this.modelProvinsi;

            return this.$http
                .get(
                    baseURI,
                    {
                        timeout: 100000,
                        'headers': {
                            'AppToken': this.$settings.jwtSecret,
                        }
                    }
                )
                .then((response) => {
                    this.kota = response.data.datas;
                    // this.modelKota = this.detailUser.kota;
                }).catch(error => {

                    console.log('Error:', error);

                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });
        },*/

        loadKota(kode_provinsi) {
            const baseURI = this.$settings.endPoint + "daerah/select-kota/" + kode_provinsi;

            return this.$http
                .get(
                    baseURI,
                    {
                        timeout: 100000,
                    }
                )
                .then((response) => {
                    this.kota = response.data.datas;
                    // this.modelKota = this.detailUser.kota;
                }).catch(error => {

                    console.log('Error:', error);

                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });
        },
        /*loadKecamatan() {
            const baseURI = this.$settings.endPoint + "master-kota/kecamatan/" + this.modelKota;

            return this.$http
                .get(
                    baseURI,
                    {
                        timeout: 100000,
                        'headers': {
                            'AppToken': this.$settings.jwtSecret,
                        }
                    }
                )
                .then((response) => {
                    this.kecamatan = response.data.datas;
                    // this.modelKecamatan = this.detailUser.kecamatan;
                }).catch(error => {

                    console.log('Error:', error);

                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });
        },*/

        loadKecamatan(kode_kota) {
            const baseURI = this.$settings.endPoint + "daerah/select-kecamatan/" + kode_kota;

            return this.$http
                .get(
                    baseURI,
                    {
                        timeout: 100000,
                    }
                )
                .then((response) => {
                    this.kecamatan = response.data.datas;
                    // this.modelKecamatan = this.detailUser.kecamatan;
                }).catch(error => {

                    console.log('Error:', error);

                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });
        },
        riwayat() {
            this.$router.push("/user/redeem-history");
        },
        getPoint() {
            const userDatas = this.$getUserInfo();
            var user_id = userDatas.sub.user_id;
            const baseURI = this.$settings.endPoint + "pengguna/detail/" + user_id;
            this.isLoading = true;
            return this.$http
                .get(
                    baseURI, { timeout: 10000 }
                )
                .then((response) => {
                    this.isLoading = false;
                    this.points = response.data.datas;
                }).catch(error => {

                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });


        },
        resetForm() {
            this.forms.produk = "";
            this.forms.produk_id = "";
            this.forms.point = "";
            this.forms.nama_lengkap = "";
            this.forms.wa = "";
            this.forms.alamat_lengkap = "";
        },
        submitData() {
            this.isLoading = true
            let formData = new FormData();

            formData.append("produk", this.forms.produk);
            formData.append("produk_id", this.forms.produk_id);
            formData.append("point", this.forms.point);
            formData.append("nama_lengkap", this.forms.nama_lengkap);
            formData.append("wa", this.forms.wa);
            formData.append("alamat_lengkap", this.forms.alamat_lengkap);
            formData.append("kota", this.modelKota.nama);
            formData.append("kecamatan", this.modelKecamatan);
            formData.append("kode_pos", this.forms.kode_pos);

            const baseURI = this.$settings.endPoint + "redeem-produk/create";
            this.$http
                .post(baseURI, formData, { timeout: 10000 })
                .then((response) => {
                    this.isLoading = false
                    if (response.data.status === 200) {
                        this.resetForm();
                        this.changeShow()
                        this.errors = [];
                        //console.log(response.data.datas.user_id);
                        // var params = this.$onRandom(response.data.datas.id);
                        // window.location.href = "/article/detail/" + params + "?status=200&msg=Successfully";
                        this.success('Berhasil');
                    } else {
                        this.errors = response.data.errors;
                        this.resultError(response.data.errors);
                    }
                }).catch(error => {
                    this.isLoading = false;
                    if (error.code == "ECONNABORTED") {
                        this.error("Please Check Your Internet Connection");
                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.error(error.response.data.errors.message);
                            } else {
                                this.$router.push("/page-not-found");
                            }
                        }

                    }


                });
        },


        changeShow() {
            if (this.show === false) {
                this.show = true
            } else {
                this.show = false
            }
            // this.slickOptions.autoplay = false
        },
        previousPage() {
            this.$router.go(-1);
            //window.history.back();
        },
        exchangePoints(productId, productNama, productPoint) {
            this.changeShow()
            this.forms.produk_id = productId
            this.forms.produk = productNama
            this.forms.point = productPoint
        },
        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
        },
        loadProduct() {
            const baseURI = this.$settings.endPoint + "master-produk/get-produk";

            //var columnFilters = this.serverParams.columnFilters;
            this.isLoading = true;
            return this.$http
                .get(
                    baseURI,
                    { timeout: 10000 }
                )
                .then((response) => {
                    this.isLoading = false;
                    this.products = response.data.datas;
                }).catch(error => {

                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });
        },
        loadSetting() {
            const baseURI = this.$settings.endPoint + "setting/detail/1";
            this.isLoading = true;
            return this.$http
                .get(
                    baseURI,
                    { timeout: 10000 }
                )
                .then((response) => {
                    this.isLoading = false;
                    this.setting = response.data.datas;
                    var today = new Date();
                    var options = { weekday: 'long' };
                    this.todayName = today.toLocaleDateString('en-US', options);
                }).catch(error => {

                    if (error.code == "ECONNABORTED") {
                        this.error(this.$t("check_internet_connection_txt"));

                    } else {

                        if (error.response) {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                                this.resultError(error.response.data.errors);
                            } else if (error.response.status === 500) {
                                this.$router.push('/server-error');
                            } else if (error.response.status === 401) {
                                this.$router.push("/authorized-error");
                            } else {
                                this.$router.push('/page-not-found');
                            }
                        }

                    }
                });
        },
        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.error(objectData[y]);
                }
            }
        },
        handleChange(code) {
            this.code = code;
        },
        refreshCaptchaCode() {
            this.$refs.captcha.refreshCaptcha();
        },
        makeOffer() {

        },

        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },

        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.fade(false);
            }, 1000); // hide the message after 3 seconds
        },
    },
    events: {},
    created: function () { },
    mounted() {
        this.loading();
        this.fetchIt();
        this.loadProduct();
        this.loadSetting();
        this.getPoint();
        this.loadProvinsi();
    },
};
</script>
<style scoped>
@import '../_partials/partial.css';

.detail-info {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 33px;
    color: #295985;
}

.detail-poin {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #092641;
}

.detail-available {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #0c862b;
}

.section-home {
    padding: 16px 24px;
}

/*.section-home p {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;

    color: #295985;
    margin: 0px;
    padding: 16px 22px 0px 22px;
}*/

.section-home .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.section-home .header img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 100%;
    margin: 10px;

}

.product {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 5px;
    padding: 5px;
    text-align: left;
    width: 45%;
    /* Adjust the width as needed */
    font-size: 13pt;
}

.product p {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    color: #295985;
    margin: 5px;
    /* padding: 16px 22px 0px 22px; */
}

.product img {
    max-width: 100%;
    height: 150px;
}

.product-name {
    font-size: 15px;
    color: #333;
    margin: 5px;
}

.product-stock {
    color: #2d2a43;
    margin: 5px 2px;
    font-size: 12px;
    line-height: 10;
}

.product-description {
    color: #666;
    line-height: 1.4;
}
.attention-box {
    background-color: #fff7e6;
    border: 2px solid #ffc107;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    padding: 20px;
    margin: 20px;
    text-align: justify;
}

.attention-box h2 {
    color: #ff9800;
    margin-top: 0;
    font-size: 18px;
    text-align: center;
}

.attention-box ol {
    padding-left: 5px;
    padding-right: 5px;
    margin: 5px 0;
    color: #333;
}

.attention-box li {
    font-size: 10pt;
    margin-bottom: 10px;
    line-height: 1.2;
}

.attention-box li:last-child {
    margin-bottom: 0;
}
</style>